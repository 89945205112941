document.addEventListener('turbolinks:load', () => {
  
  // courses/shared start
  window.selectMedia = function(media_id){
    $("#media_id").val(media_id)
    console.log("name_media_"+ media_id +"")
    console.log($("#name_media_"+ media_id +"").text())
    console.log($("#uuid_media_"+ media_id +"").val())
    $('#video_tag').attr('src', $("#uuid_media_"+ media_id +"").val());
    $('#video_tag').removeClass("d-none")
    $("#video_name").text($("#name_media_"+ media_id +"").text())
    $('#video_name').removeClass("d-none")
    $("#video_placeholder").addClass("d-none")
    $("#video_colse_btn").removeClass("d-none")
    $("#tabs_uploader_media").addClass("d-none")
    $("#media-drop").addClass("d-none")
  }

  window.selectDifferentMedia = function(){
    $("#video_tag").addClass("d-none")
    $("#video_name").addClass("d-none")
    $("#video_placeholder").removeClass("d-none")
    $("#video_colse_btn").addClass("d-none")
    $("#tabs_uploader_media").removeClass("d-none")
  }

  window.openMediaTabs = function(){
    $("#tabs_uploader_media").removeClass("d-none")
    $("#media-drop").removeClass("d-none")
    $("#tabs_uploader_media").css({
      top   : $("#input").offset().top + 50,
      left  : $("#input").offset().left,
      width : $("#input").width() + 8
      })
    $("#tabs_uploader_medi").css({
      width : "100vw"
    })
  }

  window.closeMediaTabs = function(){
    $("#tabs_uploader_media").addClass("d-none")
    $("#media-drop").addClass("d-none")
  }

  window.uploadeMedia = function(files){
    $("#upload_media_btn").click()
    $("#media_info").removeClass("d-none")
    $("#media_file_name").text(files.files[0].name)
  }

  // courses/shared end
});
