document.addEventListener('DOMContentLoaded', () => {
  $(document).on("click", ".destoy-btn", function(){
    $("#destroy-modal").modal("show")

    $(".modal-backdrop").eq(-1).css({
      "z-index": "1600",
      "display": "block"
    })
    $("#destroy-modal").eq(-1).css({
      "display": "block"
    })
  })

  $(document).on("click", ".btn-destroy", function(){
    $(".modal-backdrop").eq(-1).css({
      "display": "none"
    })
    $("#destroy-modal").hide()
  })
});
